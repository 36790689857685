<template>
  <!-- 案例展示 -->
  <div class="vr">
    <Banner v-bind="bannerProps" />
    <!-- 正文 -->
    <div class="vrBox">
      <!-- 分类按钮 -->
      <div class="classBtnBox">
        <div class="a">
          <span
            class="right"
            :class="{ active: mainindex == null }"
            @click="all"
          >全部</span>
          <template v-for="(item, index) in vrClassList">
            <div
              :key="item.id"
              class="b"
              :class="{ active: index == mainindex }"
              @click="onMain(item.id, index)"
            >
              <span class="right">
                {{ item.vrClassName }}
              </span>
            </div>
          </template>
        </div>
      </div>

      <!-- 案例 -->
      <ElRow id="routerhash">
        <ElCol v-for="item in vrList" :key="item.id" :span="12">
          <a :href="item.url" target="_blank">
            <ElCard :body-style="{ padding: '0px' }">
              <div class="img">
                <img :src="item.photo" class="image">
              </div>
              <div class="wenzi">
                <div class="left">
                  <span>{{ item.name }}</span>
                  <!-- <time class="time">{{ item.time }}</time> -->
                </div>
                <div class="button">点击进入</div>
              </div>
            </ElCard>
          </a>
        </ElCol>
      </ElRow>

      <!-- 分页按钮 -->
      <PaginationButton
        v-show="total > limit"
        :total="total"
        :page="pages"
        :limit="limit"
        @currentChange="currentChange"
      />
    </div>
  </div>
</template>

<script>
import { vrbanner, vrClass } from '../api/home';
import PaginationButton from '@/components/Pagination.vue';
import Banner from '@/components/banner.vue';

export default {
  name: 'VrIndex',
  components: {
    Banner,
    PaginationButton,
  },
  data() {
    return {
      bannerProps: {
        backgroundImage: 'https://img.kcgeis.com/2047625914.jpg',
        cnTitle: 'VR全景',
        cnTitleColor: '#fff',
        enTitle: 'VR-PANORAMA',
        enTitleColor: '#018835',
      },
      total: 0,
      pages: 1, // 默认页数
      limit: 6, // 每页显示
      classId: null,
      mainindex: null,
      vrClassList: [],
      vrList: [],
    };
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      async handler(newV) {
        const { index } = newV;
        await this.getClass();
        this.$nextTick(() => {
          const { id } = newV;
          this.onMain(id, index);
        });
      },
    },
  },

  async created() {
    await this.getData();
  },

  methods: {
    currentChange(e) {
      this.pages = e;
      this.getData();
    },
    all() {
      this.mainindex = null;
      this.classId = '';
      this.getData();
    },
    onMain(id, index) {
      this.mainindex = index;
      this.classId = id;
      this.getData();
    },
    async getClass() {
      try {
        const classData = await vrClass();
        this.vrClassList = classData.data;
      } catch (error) {
        this.$message.error(error);
      }
    },
    async getData() {
      try {
        const params = {
          vrClassId: this.classId,
          limit: this.limit,
          pages: this.pages,
        };
        const infoData = await vrbanner(params);
        this.vrList = infoData.data.records;
        this.total = infoData.data.total;
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  height: 100%;
  border-radius: 5px;
  background-color: #018835;
  color: #fff;
}
.vr {
  width: 100%;
  .vrBox {
    width: 1200px;
    margin: 40px auto 0;

    .classBtnBox {
      // width: 100%;
      border-bottom: 1px #e5e5e5 solid;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .a {
        width: 1200px;
        height: 50px;
        display: flex;
        margin-left: 10px;

        .b {
          margin: 0 10px;
          width: 100px;
        }
        .right {
          height: 100%;
          width: 100px;
          padding: 5px;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.5s;
          &:hover {
            cursor: pointer;
            border-radius: 5px;
            background-color: #018835;
            color: #fff;
            transition: 0.5s;
          }
        }
      }
    }
    .el-row {
      margin: 30px 0;
      .el-card {
        margin: 20px;
        .img {
          height: 300px;

          overflow: hidden;
        }
        .image {
          height: 100%;
          width: 100%;
          object-fit: cover;
          transition: all 0.5s;
        }
        &:hover .button {
          color: #fff;
          background-color: #018835;
          transition: all 0.3s;
        }
        &:hover .image {
          transform: scale(1.1);
          transition: all 0.5s;
        }
      }
      .wenzi {
        display: flex;
        padding: 16px;
        justify-content: space-between;
        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          span {
            font-size: 14px;
            color: #666;
          }
        }
        .button {
          width: 140px;
          height: 40px;
          color: #2b2b2b;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          border: #018835 1px solid;
          font-size: 14px;
          transition: all 0.3s;
        }
      }
    }
  }
}
</style>
